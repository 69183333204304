import styled from 'styled-components';
import { device } from '../../principles/Breakpoints';
import { Colors } from '../../atoms/Copy/Copy.types';
import { colors } from '../../atoms/Copy/Copy.styled';
import Headline from '../../atoms/Headline';

interface StyledAccordionContentProps {
  open: boolean;
}
interface StyledIconButtonProps {
  open: boolean;
  color: Colors;
}

export const StyledAccordion = styled.div`
  padding-bottom: var(--distance-l);
`;

export const StyledImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: var(--distance-l);
`;

export const StyledHeadline = styled(Headline)`
  margin-bottom: var(--distance-m);
`;

export const StyledAccordionContent = styled.div<StyledAccordionContentProps>`
  margin-top: var(--distance-m);
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-slow);

  ${({ open }) =>
    open &&
    `
      max-height: 100vh;
      transition: max-height var(--transition-slow);
    `}
`;

export const StyledIconButton = styled.button<StyledIconButtonProps>`
  position: absolute;
  bottom: var(--distance-l);
  left: 50%;
  width: var(--icon-size-m);
  transform: translateX(-50%);
  transition: transform var(--transition-slow);
  ${({ color }) => colors[color]};

  @media ${device.mobile} {
    bottom: var(--distance-m);
  }

  ${({ open }) =>
    open &&
    `
      transform: translateX(-50%) rotate(180deg);
      transition: transform var(--transition-slow);
    `}
`;
