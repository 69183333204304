import styled, { keyframes } from 'styled-components';
import type { Variant, Position } from './Tooltip.types';
import { device } from '../../principles/Breakpoints';

interface StyledTooltipButtonProps {
  open: boolean;
  $variant: Variant;
}

interface StyledTooltipIconProps {
  open: boolean;
}

interface StyledTooltipProps {
  open: boolean;
  $position: Position;
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const StyledTooltipWrapper = styled.div`
  position: relative;
  animation: ${fadeIn} var(--transition-medium) ease-in forwards;
`;

export const StyledTooltipButton = styled.button<StyledTooltipButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--tooltip-size-m);
  height: var(--tooltip-size-m);
  padding: var(--distance-xs);
  transition: background-color var(--transition-medium);

  ${({ $variant, open }) => {
    if ($variant === 'plus') {
      return `
        background: var(--color-background-brand);
      `;
    }
    if ($variant === 'thumb' && !open) {
      return `
        background: var(--color-background-highlight-light);
      `;
    }
    if ($variant === 'thumb' && open) {
      return `
        background: var(--color-background-highlight-dark);
      `;
    }
  }};
`;

export const StyledTooltipIcon = styled.svg<StyledTooltipIconProps>`
  width: 100%;
  color: var(--color-text-light);
  transition: transform var(--transition-medium);

  ${({ open }) =>
    open &&
    `
      transform: rotate(45deg);
    `}
`;

export const StyledTooltip = styled.div<StyledTooltipProps>`
  --arrow-size: 20px;

  position: absolute;
  bottom: calc(var(--tooltip-size-m) + var(--distance-m));
  padding: var(--distance-s);
  width: 280px;
  background: var(--color-background-light);
  text-align: left;
  opacity: 0;
  pointer-events: none;

  transition: opacity var(--transition-medium);

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: calc(var(--arrow-size) * -1);
    width: 0;
    height: 0;
    border-left: calc(var(--arrow-size) * 0.8) solid transparent;
    border-right: calc(var(--arrow-size) * 0.8) solid transparent;
    border-top: var(--arrow-size) solid var(--color-background-light);
  }

  ${({ $position }) => {
    if ($position === 'left') {
      return `
        right: 0;

        &:before {
          right: calc(var(--tooltip-size-m) / 2);
          transform: translateX(50%);
        }
      `;
    }
    if ($position === 'right') {
      return `
        left: 0;
        &:before {
          left: calc(var(--tooltip-size-m) / 2);
          transform: translateX(-50%);
        }
      `;
    }
  }}

  ${({ open }) =>
    open &&
    `
      opacity: 1;
    `}

  @media ${device.mobile} {
    position: fixed;
    inset: 5vw;
    top: calc(var(--mobile-header-height) + 5vw);
    width: auto;
    z-index: var(--z-index-overlay);

    &:before {
      display: none;
    }
  }
`;

export const StyledTooltipCloseButtonWrapper = styled.div`
  width: 100%;
  text-align: right;
`;

export const StyledTooltipCloseButton = styled.svg`
  margin-bottom: var(--distance-s);
  width: var(--icon-size-s);
  rotate: 45deg;

  @media ${device.desktop} {
    display: none;
  }
`;

export const StyledTooltipShim = styled.div`
  position: fixed;
  inset: 0;
  z-index: var(--z-index-overlay-shim);

  @media ${device.mobile} {
    background: var(--color-background-dark-transparent);
  }
`;
