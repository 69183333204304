import styled, { keyframes } from 'styled-components';
import Copy from '../../atoms/Copy';

export const StyledLoading = styled.div`
  display: flex;
  justify-content: center;
  height: 50vh;
  align-items: center;
`;

export const StyledLoadingText = styled(Copy)`
  position: absolute;
  top: 50%;
  left: 50%;
  padding: var(--distance-xs) var(--distance-m);
  background: var(--color-background-light);
  transform: translate(-50%);
`;

const drip = keyframes`
  from {
    opacity: 0;
    transform: translateY(-80%);
  }
  5% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 0;
    transform: translateY(80%);
  }
  `;

export const StyledLoader = styled.svg`
  width: 60px;
  opacity: 0;
  transform: translateY(-80%);
  animation: ${drip} 1.5s infinite ease-in;

  & + & {
    margin-left: 20px;
  }

  &:nth-of-type(1) {
    scale: 0.8;
    animation-delay: 0.4s;
  }
  &:nth-of-type(3) {
    scale: 0.6;
    animation-delay: 0.8s;
  }
`;
