import { createRoot } from 'react-dom/client';
import App from './App';
import './lib/i18n';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import Loading from './components/page/Loading';

const domNode = document.getElementById('root') as HTMLElement;
const root = createRoot(domNode);

root.render(
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <App />
    </Suspense>
  </BrowserRouter>,
);
