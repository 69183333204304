import styled, { css, keyframes } from 'styled-components';
import Tooltip from '../../atoms/Tooltip';
import { device } from '../../principles/Breakpoints';

interface StyledInteractiveImageHotspotProps {
  x: number;
  y: number;
  zIndex: number;
}

interface StyledInteractiveImageIconProps {
  $hidden?: boolean;
}

const imageMinWidth = '600px';

export const StyledInteractiveImageMobileScrollWrapper = styled.div`
  width: 100%;
  max-width: 100%;

  @media (max-width: ${imageMinWidth}) {
    position: relative;
    overflow-x: scroll;
  }
`;

const pulse = keyframes`
  0%, 50%, 100% {
    transform: translateY(-50%) scale(1);
  }
  25%, 75% {
    transform: translateY(-50%) scale(0.9);
  }
`;

export const StyledInteractiveImageIcon = styled.svg<StyledInteractiveImageIconProps>`
  position: absolute;
  top: 50%;
  right: 0;
  width: var(--icon-size-m);
  transform-origin: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  opacity: ${({ $hidden }) => ($hidden ? `0` : `1`)};
  pointer-events: none;
  z-index: var(--z-index-stack);
  transition: opacity var(--transition-fast) ease;

  animation: ${pulse} 3s infinite ease;

  @media ${device.desktop} {
    display: none;
  }
`;

export const StyledInteractiveImageWrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: ${imageMinWidth};
`;

export const StyledInteractiveImageHotspot = styled(Tooltip)<StyledInteractiveImageHotspotProps>`
  position: absolute;
  transform: translate(-50%, -50%);
  ${({ x, y, zIndex }) => `
    top: ${y}%;
    left: ${x}%;
    z-index: ${zIndex}
  `}
`;

export const StyledInteractiveImage = styled.img`
  width: 100%;
`;
