import styled from 'styled-components';
import Tooltip from '../../atoms/Tooltip';
import { device } from '../../principles/Breakpoints';

interface StyledDropContainerProps {
  $tooltipPhase?: boolean;
}

export const StyledDropContainer = styled.div<StyledDropContainerProps>`
  position: fixed;
  top: 50%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  translate: 0 -50%;
  z-index: ${({ $tooltipPhase }) =>
    $tooltipPhase ? 'var(--z-index-scrolling-hotspot)' : 'var(--z-index-drop)'};

  @media ${device.mobile} {
    top: ${({ $tooltipPhase }) => ($tooltipPhase ? '15%' : '50%')};
  }
`;

export const StyledTooltip = styled(Tooltip)`
  position: absolute;
  right: 0;
`;
