import styled from 'styled-components';
import { device } from '../../principles/Breakpoints';

export const StyledDivider = styled.hr`
  display: block;
  margin: var(--distance-xl) auto;
  width: 100%;
  max-width: 520px;
  border: none;
  border-bottom: 10px solid var(--color-brand);
  appearance: none;

  @media ${device.mobile} {
    margin: var(--distance-l) auto;
  }
`;
