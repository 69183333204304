import { StyledImage, StyledRoundedImage } from './RoundedImage.styled';

interface RoundedImageProps {
  image: string;
  alt: string;
}

const RoundedImage = ({ image, alt }: RoundedImageProps) => (
  <StyledRoundedImage>
    <StyledImage src={image} alt={alt} />
  </StyledRoundedImage>
);

export default RoundedImage;
