import styled from 'styled-components';

interface StyledDropProps {
  $hide?: boolean;
}

export const StyledDropWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledDrop = styled.svg<StyledDropProps>`
  width: 6.7%;
  max-width: 72px;
  padding: 2px; // prevent cut off edges in Safari

  transition: opacity 1s ease-in;

  ${({ $hide }) =>
    $hide
      ? `
    opacity: 0;
    transition: none;
  `
      : `
    opacity: 1;
  `};
`;
