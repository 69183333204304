import { useRef } from 'react';
import Copy from '../../atoms/Copy';
import Headline from '../../atoms/Headline';
import { useCountUp } from 'react-countup';
import { StyledCounter } from './Counter.styled';

interface CounterProps {
  value: string;
  headline: string;
  copy: string;
  decimals?: boolean;
}

const Counter = ({ value, headline, copy, decimals }: CounterProps) => {
  const countUpRef = useRef(null);
  useCountUp({
    ref: countUpRef,
    start: 0,
    end: parseFloat(value),
    duration: 2,
    decimals: decimals ? 1 : 0,
    decimal: ',',
    enableScrollSpy: true,
    scrollSpyDelay: 500,
  });

  return (
    <StyledCounter>
      <Headline as="h2" color="brand" size="xl" fontWeight="medium">
        <span ref={countUpRef} />
      </Headline>
      <Headline as="h4" color="brand" size="xs" htmlText={headline} />
      <Copy color="brand" fontWeight="regular" htmlText={copy} />
    </StyledCounter>
  );
};

export default Counter;
