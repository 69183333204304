import styled, { css } from 'styled-components';
import type { Colors, Sizes, FontWeights } from './Headline.types';

export const sizes = {
  xs: css`
    font-size: var(--font-size-xxs);
    line-height: var(--line-height-s);
  `,
  s: css`
    font-size: var(--font-size-xs);
    line-height: var(--line-height-m);
  `,
  m: css`
    font-size: var(--font-size-m);
  `,
  l: css`
    font-size: var(--font-size-l);
  `,
  xl: css`
    font-size: var(--font-size-xl);
  `,
} as const;

export const colors = {
  dark: css`
    color: var(--color-text-dark);
  `,
  light: css`
    color: var(--color-text-light);
  `,
  brand: css`
    color: var(--color-text-brand);
  `,
} as const;

export const fontWeights = {
  light: css`
    font-weight: var(--font-weight-light);
  `,
  regular: css`
    font-weight: var(--font-weight-regular);
  `,
  medium: css`
    font-weight: var(--font-weight-medium);
  `,
  bold: css`
    font-weight: var(--font-weight-bold);
  `,
} as const;

interface StyledHeadlineProps {
  size: Sizes;
  color: Colors;
  fontWeight: FontWeights;
}

export const StyledHeadline = styled.h1<StyledHeadlineProps>`
  margin: 0;
  margin-bottom: var(--distance-s);
  ${({ size }) => sizes[size]};
  ${({ fontWeight }) => fontWeights[fontWeight]};
  text-transform: uppercase;
  ${({ color }) => colors[color]};
`;
