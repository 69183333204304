import styled from 'styled-components';
import { device } from '../../principles/Breakpoints';

export const StyledCounter = styled.div`
  @media ${device.mobile} {
    &:not(:first-child) {
      margin-top: var(--distance-l);
    }
  }
`;
