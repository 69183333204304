import { ForwardedRef, MutableRefObject, forwardRef, useState } from 'react';
import Block from '../../atoms/Block';
import type { BlockColor } from '../../atoms/Block/Block.types';
import Copy from '../../atoms/Copy';
import {
  StyledAccordionContent,
  StyledIconButton,
  StyledHeadline,
  StyledImageWrapper,
  StyledAccordion,
} from './Accordion.styled';
import ChevronIcon from '/public/assets/chevron.svg';
import type { Colors } from '../../atoms/Copy/Copy.types';
import RoundedImage from '../../atoms/RoundedImage';

interface AccordionProps {
  image?: string;
  imageAlt?: string;
  headline: string;
  shortText?: string;
  blockColor?: BlockColor;
  textColor?: Colors;
  ref?: MutableRefObject<HTMLElement | null>;
  children: React.ReactNode;
}

type Ref = ForwardedRef<HTMLElement | null>;

const Accordion = forwardRef(
  (
    {
      image,
      imageAlt,
      headline,
      shortText,
      blockColor = 'brand',
      textColor = 'light',
      children,
    }: AccordionProps,
    ref: Ref,
  ) => {
    const [open, setOpen] = useState(false);

    const toggleOpen = () => {
      setOpen(!open);
    };

    return (
      <Block color={blockColor} ref={ref}>
        <StyledAccordion>
          {!!image && !!imageAlt && (
            <StyledImageWrapper>
              <RoundedImage image={image} alt={imageAlt} />
            </StyledImageWrapper>
          )}
          <StyledHeadline forwardedAs="h3" size="m" color={textColor}>
            {headline}
          </StyledHeadline>
          {!!shortText && (
            <Copy color={textColor} fontWeight="regular" size="l" htmlText={shortText} />
          )}

          <StyledAccordionContent open={open}>{children}</StyledAccordionContent>

          <StyledIconButton open={open} onClick={toggleOpen} color={textColor}>
            <ChevronIcon />
          </StyledIconButton>
        </StyledAccordion>
      </Block>
    );
  },
);

export default Accordion;
