import { UIEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  StyledInteractiveImage,
  StyledInteractiveImageHotspot,
  StyledInteractiveImageIcon,
  StyledInteractiveImageMobileScrollWrapper,
  StyledInteractiveImageWrapper,
} from './InteractiveImage.styled';
import type { InteractiveImageHotspot } from './InteractiveImage.types';
import ArrowIcon from '/public/assets/arrow.svg';

interface InteractiveImageProps {
  hotspots: InteractiveImageHotspot[];
  image: string;
  imageAlt: string;
}

const InteractiveImage = ({ hotspots, image, imageAlt }: InteractiveImageProps) => {
  const { t } = useTranslation();
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const [hideArrow, setHideArrow] = useState(false);

  const hasScrolledHorizontally = (event: UIEvent<HTMLDivElement>) => {
    const scrollLeft = event.currentTarget.scrollLeft;
    setHideArrow(scrollLeft > 0);
  };

  return (
    <StyledInteractiveImageMobileScrollWrapper
      ref={scrollWrapperRef}
      onScroll={hasScrolledHorizontally}
    >
      <StyledInteractiveImageIcon as={ArrowIcon} $hidden={hideArrow} />
      <StyledInteractiveImageWrapper>
        {hotspots.map(({ x, y, zIndex, text }) => (
          <StyledInteractiveImageHotspot
            key={`${x}${y}${text}`}
            x={x}
            y={y}
            zIndex={zIndex}
            variant="plus"
            position={x > 50 ? 'left' : 'right'}
          >
            {t(text)}
          </StyledInteractiveImageHotspot>
        ))}
        <StyledInteractiveImage src={image} alt={imageAlt} />
      </StyledInteractiveImageWrapper>
    </StyledInteractiveImageMobileScrollWrapper>
  );
};

export default InteractiveImage;
