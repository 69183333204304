import styled, { css } from 'styled-components';
import { device } from '../../principles/Breakpoints';
import type { BlockColor } from './Block.types';

interface StyledBlockProps {
  $color?: BlockColor;
  $image?: string;
}

interface StyledBlockBackgroundProps {
  $image?: string;
}

interface StyledBlockContentProps {
  $grid?: boolean;
}

interface StyledDropProps {
  animationPosition: number;
}

export const colors = {
  transparent: css`
    background-color: transparent;
  `,
  brand: css`
    background-color: var(--color-brand);
  `,
} as const;

export const blockBase = css`
  padding: 0 var(--distance-m);

  @media ${device.mobile} {
    padding: 0 0;
  }
`;

export const StyledBlock = styled.section<StyledBlockProps>`
  ${blockBase}

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  z-index: var(--z-index-block);

  ${({ $color }) =>
    !!$color &&
    `
    margin: var(--distance-xl) 0;
    ${colors[$color]}
    `}
  ${({ $image }) =>
    !!$image &&
    `
      padding: var(--distance-xl) 0;
      background-image: url(${$image});
      background-size: contain;
      background-repeat: repeat-y;

      @media ${device.mobile} {
        padding: var(--distance-l) var(--distance-s);
      }
    `}
`;

export const StyledBlockBackground = styled.div<StyledBlockBackgroundProps>`
  position: fixed;
  inset: 0;
  background-image: ${({ $image }) => `url(${$image})`};
  background-size: cover;
  background-repeat: repeat-y;
`;

export const StyledBlockWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--distance-xl) 0;
  width: 100%;
  max-width: 1100px;
  z-index: var(--z-index-block-wrapper);

  @media ${device.mobile} {
    padding: var(--distance-l) var(--distance-s);
  }
`;

export const StyledBlockContent = styled.div<StyledBlockContentProps>`
  width: 100%;
  max-width: 796px;

  ${({ $grid }) => {
    if (!!$grid) {
      return `
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30%, 1fr));
      gap: var(--distance-m);

      @media ${device.mobile} {
        display: block
      }
    `;
    }
    return '';
  }}
`;
