import { useRef, useState } from 'react';
import {
  StyledTooltip,
  StyledTooltipButton,
  StyledTooltipCloseButton,
  StyledTooltipCloseButtonWrapper,
  StyledTooltipIcon,
  StyledTooltipShim,
  StyledTooltipWrapper,
} from './Tooltip.styled';
import type { Variant, Position } from './Tooltip.types';
import PlusIcon from '/public/assets/plus.svg';
import ThumbIcon from '/public/assets/thumb.svg';
import { createPortal } from 'react-dom';
import useIsMobile from '../../../hooks/useIsMobile';

interface TooltipProps {
  variant: Variant;
  position: Position;
  className?: string;
  children: React.ReactNode;
}

const Tooltip = ({ variant, position, className, children }: TooltipProps) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
  const isMobile = useIsMobile();

  const IconMap = () => {
    if (variant === 'plus') {
      return PlusIcon;
    }
    if (variant === 'thumb') {
      return ThumbIcon;
    }
  };

  const closeTooltip = () => {
    setOpen(false);
  };
  const openTooltip = () => {
    setOpen(true);
  };

  const TooltipTip = () => (
    <StyledTooltip ref={ref} open={open} $position={position}>
      <StyledTooltipCloseButtonWrapper>
        <StyledTooltipCloseButton as={PlusIcon} />
      </StyledTooltipCloseButtonWrapper>
      {children}
    </StyledTooltip>
  );

  return (
    <StyledTooltipWrapper className={className}>
      <StyledTooltipButton ref={ref} onClick={openTooltip} $variant={variant} open={open}>
        <StyledTooltipIcon as={IconMap()} open={variant === 'plus' && open} />
      </StyledTooltipButton>
      {isMobile ? createPortal(<TooltipTip />, document.body) : <TooltipTip />}

      {createPortal(<>{open && <StyledTooltipShim onClick={closeTooltip} />}</>, document.body)}
    </StyledTooltipWrapper>
  );
};

export default Tooltip;
