import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Copy from '../../atoms/Copy';
import Headline from '../../atoms/Headline';
import LottiePlayer from '../../atoms/LottiePlayer';
import useIsMobile from '../../../hooks/useIsMobile';
import { useInView } from 'react-intersection-observer';
import { SpecialAnimationInViewContext } from '../../../context/SpecialAnimationInViewContext';

const SpecialAnimation = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [inViewRef, inView] = useInView({ threshold: 0.1 });
  const { setInView } = useContext(SpecialAnimationInViewContext);

  useEffect(() => {
    if (!setInView) return;

    setInView(inView);
  }, [inView]);

  return (
    <>
      {isMobile && (
        <>
          <Headline size="m" color="dark" htmlText={t('oxigenHeadline')} />
          <Copy color="dark" fontWeight="bold" size="m" marginBottom htmlText={t('oxigenCopy')} />
        </>
      )}
      <div ref={inViewRef}>
        <LottiePlayer
          name="special"
          scrollHeight={800}
          delay={10}
          before={
            !isMobile && (
              <>
                <Headline size="m" color="dark" htmlText={t('oxigenHeadline')} />
                <Copy
                  color="dark"
                  fontWeight="bold"
                  size="m"
                  marginBottom
                  htmlText={t('oxigenCopy')}
                />
              </>
            )
          }
        />
      </div>
    </>
  );
};

export default SpecialAnimation;
