import styled from 'styled-components';

interface StyledLottiePlayerScrollAreaProps {
  $height?: number;
  $loading?: boolean;
}

interface StyledLottiePlayerWrapperProps {
  $fixedHeight: boolean;
  $attachToTop?: boolean;
}

interface StyledLottiePlayerProps {
  $marginBottom: boolean;
}

export const StyledLottiePlayerScrollArea = styled.div<StyledLottiePlayerScrollAreaProps>`
  position: relative;
  display: ${({ $loading }) => $loading && 'none'};
  height: ${({ $height }) => ($height ? `${$height}dvh` : '100%')};
  overflow: visible;
  z-index: var(--z-index-lottie-scroll-area);
`;

export const StyledLottiePlayerWrapper = styled.div<StyledLottiePlayerWrapperProps>`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $attachToTop }) => (!!$attachToTop ? 'flex-start' : 'center')};
  align-items: center;
  max-width: var(--desktop);
  margin: 0 auto;
  min-height: ${({ $fixedHeight }) => ($fixedHeight ? '100dvh' : '')};
  overflow: hidden;
`;

export const StyledLottiePlayer = styled.div<StyledLottiePlayerProps>`
  width: 100%;
  margin-bottom: ${({ $marginBottom }) => $marginBottom && 'var(--distance-m)'};
`;
