import { ForwardedRef, forwardRef, useContext } from 'react';
import {
  StyledBlock,
  StyledBlockBackground,
  StyledBlockContent,
  StyledBlockWrapper,
} from './Block.styled';
import type { BlockColor } from './Block.types';
import { SpecialAnimationInViewContext } from '../../../context/SpecialAnimationInViewContext';

interface BlockProps {
  color?: BlockColor;
  image?: string;
  grid?: boolean;
  children: React.ReactNode;
}

type Ref = ForwardedRef<HTMLElement | null>;

const Block = forwardRef(({ color, image, grid, children }: BlockProps, ref: Ref) => {
  const { inView } = useContext(SpecialAnimationInViewContext);

  return (
    <StyledBlock $color={color} $image={image} ref={ref}>
      {image && inView && <StyledBlockBackground $image={image} />}
      <StyledBlockWrapper>
        <StyledBlockContent $grid={grid}>{children}</StyledBlockContent>
      </StyledBlockWrapper>
    </StyledBlock>
  );
});

export default Block;
