import styled from 'styled-components';
import Tooltip from '../../atoms/Tooltip';
import { device } from '../../principles/Breakpoints';

interface StyledHotspotTooltipProps {
  top: number;
}

export const StyledHotspotTooltip = styled(Tooltip)<StyledHotspotTooltipProps>`
  position: absolute;
  top: ${({ top }) => `${top}px`};
  right: 0;
  transform: translateY(-50%);
  z-index: var(--z-index-hotspot);

  @media ${device.mobile} {
    transform: translateY(-80%);
  }
`;
