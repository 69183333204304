import { StyledVideo } from './Video.styled';

interface VideoProps {
  src: string;
  type: string;
  srcFallback?: string;
  typeFallback?: string;
  poster?: string;
  controls?: boolean;
}

const Video = ({ src, type, srcFallback, typeFallback, poster, controls }: VideoProps) => (
  <StyledVideo
    preload="auto"
    playsInline
    poster={poster}
    controls={controls}
    muted={!controls}
    autoPlay={!controls}
    loop={!controls}
  >
    <source src={`${src}#t=0.001`} type={type} />
    {!!srcFallback && <source src={srcFallback} type={typeFallback} />}
  </StyledVideo>
);

export default Video;
