import { useCallback, useEffect, useRef, useState } from 'react';
import { StyledHotspotTooltip } from './Hotspot.styled';
import { createPortal } from 'react-dom';
import useBodyResize from '../../../hooks/useBodyResize';
import useIsMobile from '../../../hooks/useIsMobile';
import { useInView } from 'react-intersection-observer';

interface HotpotProps {
  text?: string;
  htmlText?: string;
  children: React.ReactNode;
}

const Hotspot = ({ text, htmlText, children }: HotpotProps) => {
  const HotspotRef = useRef<Element | null | undefined>(null);
  const [top, setTop] = useState(0);
  const bodySize = useBodyResize();
  const isMobile = useIsMobile();
  const [inViewRef, inView] = useInView({ threshold: 0.6 });

  const getTopValue = () => {
    if (HotspotRef && HotspotRef.current) {
      const { current } = HotspotRef;
      const elementRect = current.getBoundingClientRect();
      const { height, top } = elementRect;
      const elementCenter = isMobile ? window.scrollY + top : window.scrollY + top + height / 2;
      setTop(elementCenter);
    }
  };

  useEffect(() => {
    setTimeout(getTopValue, 500);
  }, []);

  useEffect(() => {
    getTopValue();
  }, [bodySize]);

  const setRefs = useCallback(
    (node: Element | null | undefined) => {
      HotspotRef.current = node;
      inViewRef(node);
    },
    [inViewRef],
  );

  return (
    <div ref={setRefs}>
      {inView &&
        createPortal(
          <div>
            <StyledHotspotTooltip position="left" variant="thumb" top={top}>
              {text}
              {htmlText && <div dangerouslySetInnerHTML={{ __html: htmlText }} />}
            </StyledHotspotTooltip>
          </div>,
          document.body,
        )}
      {children}
    </div>
  );
};

export default Hotspot;
