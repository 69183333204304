import { desktop } from './GlobalStyles';

interface Size {
  desktop: string;
}

const size: Size = {
  desktop: desktop,
};

export const device = {
  mobile: `(max-width: ${size.desktop})`,
  desktop: `(min-width: ${size.desktop})`,
};
