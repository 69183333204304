import { useState, useEffect } from 'react';
import { desktop } from '../components/principles/GlobalStyles';

const useIsMobile = () => {
  const [matches, setMatches] = useState<boolean | undefined>();

  useEffect(() => {
    const media = window.matchMedia(`(max-width: ${desktop})`);

    const updateMatches = () => setMatches(media.matches);

    if (media.matches !== matches) {
      updateMatches();
    }
    window.addEventListener('resize', updateMatches);
    return () => window.removeEventListener('resize', updateMatches);
  }, [matches]);

  return matches;
};

export default useIsMobile;
