import { StyledLoader, StyledLoading, StyledLoadingText } from './Loading.styled';
import Drop1 from '/public/assets/drop-01.svg';

const Loading = () => (
  <StyledLoading>
    <StyledLoadingText>LOADING...</StyledLoadingText>
    <StyledLoader as={Drop1} />
    <StyledLoader as={Drop1} />
    <StyledLoader as={Drop1} />
  </StyledLoading>
);

export default Loading;
