import { StyledCopy } from './Copy.styled';
import type { Colors, FontWeights, Sizes } from './Copy.types';

interface CopyProps {
  as?: 'p' | 'div';
  className?: string;
  size?: Sizes;
  color?: Colors;
  fontWeight?: FontWeights;
  marginBottom?: boolean;
  children?: React.ReactNode;
  htmlText?: string;
}

const Copy = ({
  as = 'p',
  className,
  size = 'm',
  color = 'dark',
  fontWeight = 'bold',
  marginBottom,
  children,
  htmlText,
}: CopyProps) => (
  <StyledCopy
    as={as}
    className={className}
    size={size}
    color={color}
    fontWeight={fontWeight}
    $marginBottom={marginBottom}
    dangerouslySetInnerHTML={htmlText ? { __html: htmlText } : undefined}
  >
    {children}
  </StyledCopy>
);

export default Copy;
