import { useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { debounce } from 'lodash';

const useBodyResize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const body = document.body;

  const updateSize = () => {
    const bodyRect = body.getBoundingClientRect();
    setSize({ width: bodyRect.width, height: bodyRect.height });
  };

  useResizeObserver(body, debounce(updateSize, 300));

  return size;
};

export default useBodyResize;
