import LottiePlayer from '../../atoms/LottiePlayer';
import GlobalStyle from '../../principles/GlobalStyles';
import Blocks from '../Blocks';

const Home = () => (
  <>
    <GlobalStyle />
    <LottiePlayer name="intro" loop autoplay />

    <Blocks />

    <LottiePlayer name="outro" scrollHeight={400} delay={10} attachToTop />
  </>
);

export default Home;
