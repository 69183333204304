import styled from 'styled-components';
import { device } from '../../principles/Breakpoints';

export const StyledRoundedImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  border-radius: 50%;
  aspect-ratio: 1/1;
  overflow: hidden;

  @media ${device.mobile} {
    width: 33.3%;
  }
`;

export const StyledImage = styled.img`
  display: block;
  width: 100%;
`;
