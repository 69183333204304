import { useEffect, useState } from 'react';
import useScrollPosition from '../../../hooks/useScrollPosition';
import Drop from '../../atoms/Drop';
import type { DropPhaseTriggers } from '../../page/Blocks/Blocks.types';
import { StyledDropContainer, StyledTooltip } from './DropContainer.styled';
import { useTranslation } from 'react-i18next';

interface DropContainerProps {
  dropPhaseTriggers: DropPhaseTriggers;
}

const startThreshold = 100;

const DropContainer = ({ dropPhaseTriggers }: DropContainerProps) => {
  const [phase, setPhase] = useState(0);

  const { t } = useTranslation();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    const centerScrollPosition = scrollPosition + window.innerHeight / 2;

    if (scrollPosition < startThreshold) {
      setPhase(0);
    } else if (centerScrollPosition <= dropPhaseTriggers[0]) {
      setPhase(1);
    } else if (
      centerScrollPosition > dropPhaseTriggers[0] &&
      centerScrollPosition < dropPhaseTriggers[1]
    ) {
      setPhase(2);
    } else if (
      centerScrollPosition > dropPhaseTriggers[1] &&
      centerScrollPosition < dropPhaseTriggers[2]
    ) {
      setPhase(3);
    } else if (
      centerScrollPosition > dropPhaseTriggers[2] &&
      centerScrollPosition < dropPhaseTriggers[3]
    ) {
      setPhase(4);
    } else if (
      centerScrollPosition > dropPhaseTriggers[3] &&
      centerScrollPosition < dropPhaseTriggers[4]
    ) {
      setPhase(5);
    } else {
      setPhase(6);
    }
  }, [scrollPosition]);

  return (
    <StyledDropContainer $tooltipPhase={phase === 5}>
      <Drop phase={phase} />
      {phase === 5 && (
        <StyledTooltip position="left" variant="thumb">
          {t('oxigenHotspot')}
        </StyledTooltip>
      )}
    </StyledDropContainer>
  );
};

export default DropContainer;
