import type { InteractiveImageHotspot } from '../components/molecules/InteractiveImage/InteractiveImage.types';

export const InteractiveImageHotspotMap: InteractiveImageHotspot[] = [
  {
    x: 15,
    y: 30,
    zIndex: 11,
    text: 'greenFilterInteractiveMapHotspot1',
  },
  {
    x: 30,
    y: 12,
    zIndex: 10,
    text: 'greenFilterInteractiveMapHotspot2',
  },
  {
    x: 45,
    y: 70,
    zIndex: 10,
    text: 'greenFilterInteractiveMapHotspot3',
  },
  {
    x: 67,
    y: 77,
    zIndex: 11,
    text: 'greenFilterInteractiveMapHotspot4',
  },
  {
    x: 84,
    y: 70,
    zIndex: 10,
    text: 'greenFilterInteractiveMapHotspot5',
  },
];
