import { createGlobalStyle } from 'styled-components';

export const desktop = '1100px';

const GlobalStyle = createGlobalStyle`
  // << FONTS >>
  @font-face {
    font-family: "EvonikProkyonWeb";
    font-weight: 300;
    src: url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Light.woff2') format('woff2'),
    url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Light.woff') format('woff'),
    url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Light.ttf') format('ttf');
    font-display: swap;
    }

  @font-face {
  font-family: "EvonikProkyonWeb";
  font-weight: 400;
  src: url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Regular.woff2') format('woff2'),
  url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Regular.woff') format('woff'),
  url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Regular.ttf') format('ttf');
  font-display: swap;
  }

  @font-face {
  font-family: "EvonikProkyonWeb";
  font-weight: 500;
  src: url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Medium.woff2') format('woff2'),
  url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Medium.woff') format('woff'),
  url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Medium.ttf') format('ttf');
  font-display: swap;
  }

  @font-face {
  font-family: "EvonikProkyonWeb";
  font-weight: 700;
  src: url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Bold.woff2') format('woff2'),
  url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Bold.woff') format('woff'),
  url('fonts/EvonikProkyonWeb/EvonikProkyonWeb-Bold.ttf') format('ttf');
  font-display: swap;
  }

  :root {
    // << BREAKPOINTS >>
    --desktop: ${desktop};

    // << Z-INDEX >>
    --z-index-stack: 1;
    --z-index-drop: 1;
    --z-index-block: 2;
    --z-index-block-wrapper: 3;
    --z-index-lottie-scroll-area: 3;
    --z-index-scrolling-hotspot: 4;
    --z-index-hotspot: 10;
    --z-index-mobile-header: 11;
    --z-index-overlay-shim: 19;
    --z-index-overlay: 20;

    // << REAL COLORS >>
    --color-violet-eggplant: #991D85;
    --color-black: #000000;
    --color-black-transparent: rgba(0, 0, 0, .3);
    --color-white: #FFFFFF;
    --color-blue-lagoon: #008C95;
    --color-regal-blue: #004876;

    // << ABSTRACT COLORS >>
    --color-brand: var(--color-violet-eggplant);
    --color-dark: var(--color-black);
    --color-dark-transparent: var(--color-black-transparent);
    --color-light: var(--color-white);
    --color-highlight-light: var(--color-blue-lagoon);
    --color-highlight-dark: var(--color-regal-blue);

    // << USE CASE COLORS >>
    --color-text-dark: var(--color-dark);
    --color-text-light: var(--color-light);
    --color-text-brand: var(--color-brand);

    --color-background-dark: var(--color-dark);
    --color-background-dark-transparent: var(--color-dark-transparent);
    --color-background-light: var(--color-light);
    --color-background-brand: var(--color-brand);
    --color-background-highlight-light: var(--color-highlight-light);
    --color-background-highlight-dark: var(--color-highlight-dark);

    // << DISTANCES >>
    --distance-xs: 10px;
    --distance-s: 16px;
    --distance-m: 32px;
    --distance-l: 64px;
    --distance-xl: 100px;
    --distance-xxl: 500px;

    // << FONT FAMILY >>
    --font-family: "EvonikProkyonWeb", Arial;

    // << FONT SIZES >>
    --font-size-3xs: 14px;
    --font-size-xxs: 18px;
    --font-size-xs: 20px;
    --font-size-s: 23px;
    --font-size-m: 28px;
    --font-size-l: 50px;
    --font-size-xl: 130px;

    // << FONT WEIGHT >>
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-bold: 700;

    // << LINE HEIGHTS >>
    --line-height-s: 25px;
    --line-height-m: 32px;

    // << LETTER SPACINGS >>
    --letter-spacing-s: 0.02em;
    --letter-spacing-m: 0.03em;
    --letter-spacing-l: 0.07em;

    // << ICON SIZES >>
    --icon-size-s: 48px;
    --icon-size-m: 72px;

    // << TOOLTIP SIZES >>
    --tooltip-size-m: 64px;

    // << TRANSITIONS >>
    --transition-fast: 0.2s;
    --transition-medium: 0.3s;
    --transition-slow: 0.5s;

    // << BEO ELEMENT SIZES >>
    --mobile-header-height: 5rem;
  }

  @media only screen and (max-width: ${desktop}) {
  :root {
    // << FONT SIZES >>
      --font-size-3xs: 14px;
      --font-size-xxs: 18px;
      --font-size-xs: 16px;
      --font-size-s: 23px;
      --font-size-m: 24px;
      --font-size-l: 30px;
      --font-size-xl: 80px;
    }
  }

  body {
    font-family: var(--font-family);
  }
`;

export default GlobalStyle;
