import { Route, Routes } from 'react-router';
import Home from './components/page/Home/Home';

const App = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/de" element={<Home />} />
    <Route path="/en" element={<Home />} />
  </Routes>
);

export default App;
