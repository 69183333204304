import { StyledHeadline } from './Headline.styled';
import type { Colors, FontWeights, Sizes } from './Headline.types';

interface HeadlineProps {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'div';
  className?: string;
  children?: React.ReactNode;
  color: Colors;
  size: Sizes;
  fontWeight?: FontWeights;
  htmlText?: string;
}

const Headline = ({
  as = 'h1',
  className,
  children,
  color,
  size,
  fontWeight = 'bold',
  htmlText,
}: HeadlineProps) => (
  <StyledHeadline
    as={as}
    className={className}
    color={color}
    size={size}
    fontWeight={fontWeight}
    dangerouslySetInnerHTML={htmlText ? { __html: htmlText } : undefined}
  >
    {children}
  </StyledHeadline>
);

export default Headline;
