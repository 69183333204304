import { useEffect, useRef, useState } from 'react';
import bodymovin from 'lottie-web';
import type { PlayerInstance, Animations, AnimationInstances } from './LottiePlayer.types';

import IntroAnimation from './animations/intro.json';
import SpecialAnimation from './animations/special.json';
import OutroAnimation from './animations/outro.json';
import IntroAnimationMobile from './animations/intro_mobile.json';
import SpecialAnimationMobile from './animations/special_mobile.json';
import {
  StyledLottiePlayer,
  StyledLottiePlayerScrollArea,
  StyledLottiePlayerWrapper,
} from './LottiePlayer.styled';
import Loading from '../../page/Loading';
import useIsMobile from '../../../hooks/useIsMobile';

interface LottiePlayerProps {
  name: Animations;
  scrollHeight?: number;
  delay?: number;
  loop?: boolean;
  autoplay?: boolean;
  attachToTop?: boolean;
  className?: string;
  before?: React.ReactNode;
  after?: React.ReactNode;
}

type AnimationInstanceObject = {
  [key in AnimationInstances]: Object;
};

const animations: AnimationInstanceObject = {
  intro: IntroAnimation,
  introMobile: IntroAnimationMobile,
  special: SpecialAnimation,
  specialMobile: SpecialAnimationMobile,
  outro: OutroAnimation,
  outroMobile: OutroAnimation,
};

const LottiePlayer = ({
  name,
  scrollHeight,
  delay = 0,
  loop,
  autoplay,
  attachToTop,
  className,
  before,
  after,
}: LottiePlayerProps) => {
  const scrollAreaRef = useRef(null);
  const wrapperRef = useRef(null);
  const playerRef = useRef(null);
  const timelineRef = useRef<ViewTimeline | undefined>();
  const playerInstance = useRef<PlayerInstance>(undefined);

  const [loading, setLoading] = useState(true);

  const isMobile = useIsMobile();

  const updateStopPosition = () => {
    if (!playerInstance.current || !timelineRef.current) {
      return;
    }

    const currentAnimationPosition = timelineRef.current.currentTime.value - delay;

    if (currentAnimationPosition > 0 && currentAnimationPosition < 101 - delay) {
      const instance = playerInstance.current;
      const frames = instance.getDuration(true);

      const currentPosition = Math.round(frames * (currentAnimationPosition / (100 - delay)));

      instance.goToAndStop(currentPosition, true);
    }
  };

  useEffect(() => {
    if (autoplay) return;

    timelineRef.current = new ViewTimeline({
      subject: scrollAreaRef.current,
      axis: 'block',
      inset: 'auto',
    });

    document.addEventListener('scroll', () => {
      updateStopPosition();
    });

    return () => {
      document.removeEventListener('scroll', () => {
        updateStopPosition();
      });
    };
  }, []);

  useEffect(() => {
    if (!playerRef.current || isMobile === undefined) {
      return;
    }

    if (playerInstance.current) {
      playerInstance.current.destroy();
    }

    const animationName: AnimationInstances = isMobile ? `${name}Mobile` : name;

    playerInstance.current = bodymovin.loadAnimation({
      container: playerRef.current,
      animationData: animations[animationName],
      loop: !!loop,
      autoplay: !!autoplay,
      name: name,
    });

    playerInstance.current.addEventListener('DOMLoaded', () => setLoading(false));
    return () => {
      playerInstance.current?.removeEventListener('DOMLoaded', () => setLoading(false));
    };
  }, [playerRef, isMobile]);

  return (
    <>
      {loading && <Loading />}
      <StyledLottiePlayerScrollArea
        className={className}
        ref={scrollAreaRef}
        $height={scrollHeight}
        $loading={loading}
      >
        <StyledLottiePlayerWrapper
          ref={wrapperRef}
          $fixedHeight={!autoplay}
          $attachToTop={attachToTop}
        >
          {before}
          <StyledLottiePlayer ref={playerRef} $marginBottom={!!after} />
          {after}
        </StyledLottiePlayerWrapper>
      </StyledLottiePlayerScrollArea>
    </>
  );
};

export default LottiePlayer;
