import Headline from '../../atoms/Headline';
import Copy from '../../atoms/Copy';
import Block from '../../atoms/Block';
import WaterBackground from '/public/assets/water.webp';
import InteractiveBackground from '/public/assets/interactive.webp';
import Placeholder from '/public/assets/placeholder.jpg';
import LimnoplastVideo from '/public/assets/limnoplast.mp4';
import Counter from '../../molecules/Counter';
import Divider from '../../atoms/Divider';
import InteractiveImage from '../../molecules/InteractiveImage';
import Accordion from '../../molecules/Accordion';
import Video from '../../atoms/Video';
import Hotspot from '../../molecules/Hotspot';
import { InteractiveImageHotspotMap } from '../../../data/interactiveImageHotspotMap';
import { useTranslation } from 'react-i18next';
import LottiePlayer from '../../atoms/LottiePlayer';
import { useEffect, useRef, useState } from 'react';
import type { DropPhaseTriggers } from './Blocks.types';
import useBodyResize from '../../../hooks/useBodyResize';
import DropContainer from '../../molecules/DropContainer';
import SpecialAnimation from '../SpecialAnimation';
import SpecialAnimationInViewContextProvider from '../../../context/SpecialAnimationInViewContext';

const Blocks = () => {
  const DropPhaseTriggerEl1 = useRef<HTMLElement | null>(null);
  const DropPhaseTriggerEl2 = useRef<HTMLElement | null>(null);
  const DropPhaseTriggerEl3 = useRef<HTMLElement | null>(null);
  const DropPhaseTriggerEl4 = useRef<HTMLDivElement | null>(null);
  const [dropPhaseTriggers, setDropPhaseTriggers] = useState<DropPhaseTriggers>([0, 0, 0, 0, 0]);

  const bodySize = useBodyResize();
  const { t } = useTranslation();

  const updateDropPhaseTriggers = () => {
    if (
      !DropPhaseTriggerEl1.current ||
      !DropPhaseTriggerEl2.current ||
      !DropPhaseTriggerEl3.current ||
      !DropPhaseTriggerEl4.current
    ) {
      return;
    }

    const scrollPosition = window.scrollY;

    const trigger1Top = DropPhaseTriggerEl1.current.getBoundingClientRect().top + scrollPosition;
    const trigger2Top = DropPhaseTriggerEl2.current.getBoundingClientRect().top + scrollPosition;
    const trigger3Top = DropPhaseTriggerEl3.current.getBoundingClientRect().top + scrollPosition;
    const trigger4Top = DropPhaseTriggerEl4.current.getBoundingClientRect().top + scrollPosition;
    const trigger5Top =
      DropPhaseTriggerEl4.current.getBoundingClientRect().top +
      DropPhaseTriggerEl4.current.getBoundingClientRect().height +
      scrollPosition;

    setDropPhaseTriggers([trigger1Top, trigger2Top, trigger3Top, trigger4Top, trigger5Top]);
  };

  useEffect(() => {
    updateDropPhaseTriggers();
  }, [bodySize]);

  return (
    <>
      <DropContainer dropPhaseTriggers={dropPhaseTriggers} />
      <Block>
        <Headline size="s" color="brand" htmlText={t('introSubline')} />
        <Headline size="l" color="brand" htmlText={t('introHeadline')} />
        <Copy color="dark" fontWeight="bold" size="m" htmlText={t('introCopy')} />
      </Block>

      <Block ref={DropPhaseTriggerEl1} grid>
        <Counter value="3.9" decimals headline={t('counter1Headline')} copy={t('counter1Copy')} />
        <Counter value="40" headline={t('counter2Headline')} copy={t('counter2Copy')} />
        <Counter value="446" headline={t('counter3Headline')} copy={t('counter3Copy')} />
      </Block>

      <Accordion
        image={Placeholder}
        imageAlt={t('interviewImageAlt')}
        headline={t('interviewHeadline')}
        shortText={t('interviewShortText')}
      >
        <Copy color="light" fontWeight="bold" size="m" htmlText={t('interviewContent')} />
      </Accordion>

      <Block ref={DropPhaseTriggerEl2}>
        <Headline size="l" color="brand" htmlText={t('cleanupHeadline')} />
        <Copy color="dark" fontWeight="bold" size="m" htmlText={t('cleanupCopy')} />
      </Block>

      <SpecialAnimationInViewContextProvider>
        <Block image={WaterBackground} ref={DropPhaseTriggerEl3}>
          <Headline size="m" color="dark">
            {t('greenFilterHeadline')}
          </Headline>
          <Copy
            color="dark"
            fontWeight="bold"
            size="m"
            marginBottom
            htmlText={t('greenFilterCopy')}
          />
          <Hotspot htmlText={t('greenFilterHotspot')}>
            <InteractiveImage
              hotspots={InteractiveImageHotspotMap}
              image={InteractiveBackground}
              imageAlt={t('greenFilterImageAlt')}
            />
          </Hotspot>

          <Divider />

          <Headline size="m" color="dark" htmlText={t('particlesHeadline')} />
          <Copy
            color="dark"
            fontWeight="bold"
            size="m"
            marginBottom
            htmlText={t('particlesCopy')}
          />
          <Hotspot htmlText={t('particlesHotspot')}>
            <Video src={LimnoplastVideo} type="video/mp4" controls />
          </Hotspot>

          <Divider />

          <div ref={DropPhaseTriggerEl4}>
            <SpecialAnimation />
          </div>
        </Block>
      </SpecialAnimationInViewContextProvider>
    </>
  );
};

export default Blocks;
