import { StyledDrop, StyledDropWrapper } from './Drop.styled';

import Drop1 from '/public/assets/drop-01.svg';
import Drop2 from '/public/assets/drop-02.svg';
import Drop3 from '/public/assets/drop-03.svg';
import Drop4 from '/public/assets/drop-04.svg';

interface DropProps {
  phase: number;
  className?: string;
}

const Drop = ({ phase, className }: DropProps) => {
  const dropMap = () => {
    switch (phase) {
      case 0:
        return <StyledDrop $hide as={Drop1} />;
      case 1:
        return <StyledDrop as={Drop1} />;
      case 2:
        return <StyledDrop as={Drop2} />;
      case 3:
        return <StyledDrop as={Drop3} />;
      case 4:
        return <StyledDrop as={Drop4} />;
      case 5:
        return <StyledDrop $hide as={Drop4} />;
      case 6:
        return <StyledDrop $hide as={Drop4} />;
      default:
        return <StyledDrop as={Drop1} />;
    }
  };

  return <StyledDropWrapper className={className}>{dropMap()}</StyledDropWrapper>;
};

export default Drop;
