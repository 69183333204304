import { Context, Dispatch, SetStateAction, createContext, useState } from 'react';

interface ContextData {
  inView: boolean;
  setInView: Dispatch<SetStateAction<boolean>> | null;
}

interface SpecialAnimationInViewContextProviderProps {
  children: React.ReactNode;
}

export const SpecialAnimationInViewContext: Context<ContextData> = createContext({
  inView: false,
  setInView: null,
} as ContextData);

const SpecialAnimationInViewContextProvider = ({
  children,
}: SpecialAnimationInViewContextProviderProps) => {
  const [inView, setInView] = useState(false);

  return (
    <SpecialAnimationInViewContext.Provider value={{ inView, setInView }}>
      {children}
    </SpecialAnimationInViewContext.Provider>
  );
};

export default SpecialAnimationInViewContextProvider;
